<template>
  <div>
    <template v-if="!photo">
      <div class="slegend">
        PHOTO
        <span v-if="isBonusEligible">- obligatoire pour pouvoir bénéficier du bonus réparation - le vêtement doit être entièrement visible</span>
        (1 seule photo)
      </div>
      <div class="large-12 medium-12 small-12 cell">
        <label class="custom-file-upload btn btn-2">
          <input
            id="file"
            ref="file"
            accept="image/*"
            multiple
            placeholder="Sélectionnez votre photo"
            type="file"
            v-on:change="handleFileUpload()"
          />
        </label>
      </div>
    </template>
    <div v-if="isprogress" class="progressbar">
      <span>Envoi de la photo en cours...</span>
      <div class="bar">{{ percentprogress }}%</div>
    </div>
    <div v-if="photo" class="preview">
      <span>Votre photo.</span>
      <div class="bar"><img :src="`${filePath}${photo}`" alt="Votre photo"/></div>
      <a style="font-size:10px;margin-top:11px" @click="deletePhoto">Supprimer la photo</a>
    </div>
    <p v-if="error" class="error--text">{{ error }}</p>
  </div>
</template>
<style scoped>
input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 0px;
  cursor: pointer;
  height: 35px;
  width: 90%;
}

.progressbar .bar {
  height: 20px;
  background-color: #000;
  text-align: center;
  color: #fff;
}

.preview {
  max-width: 390px;
}

.slegend {
  font-weight: 500;
}
</style>
<script>
import axios from '@/axios.js'
import config from '../../../config'

export default {
  props: {
    value: {
      default: null,
      type: String
    },
    isBonusEligible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      file: '',
      totalBytes: null,
      percentprogress: 0,
      isprogress: false,
      photo: null,
      filePath: config.filePath,
      error: null
    }
  },
  created() {
    this.photo = this.$props.value
  },
  methods: {
    deletePhoto() {
      this.photo = ''
      this.$emit('image', this.photo)
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.submitFile();
    },
    submitFile() {
      this.error = null
      this.isprogress = true;
      let formData = new FormData();
      formData.append('file', this.file);
      axios.post(`${config.apiHost}/envoi-photo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const {loaded, total} = progressEvent;
          // Do something with the progress details
          this.percentprogress = Math.round((loaded / total) * 100);
          if (this.percentprogress == 100) {
            this.isprogress = false;
          }
        }
      }).then((response) => {
        this.photo = response.data.file
        this.$emit('image', this.photo)
      }).catch(() => {
        this.error = 'Une erreur est survenue, votre photo n\'a pas pu être chargée.'
      });
    },
  }
}
</script>
